import {
	BodyText,
	ButtonProps,
	Carousel as FabricCarousel,
	Flex,
	Headline,
	LayoutBox,
	TextButton,
} from '@bamboohr/fabric';
import { ClipboardPencil20x20 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import moment from 'moment';
import { useMemo } from 'react';
import { useStyles } from '../styles';
import { UpcomingSurvey } from '../types';
import Carousel from './carousel';
import { UpcomingSurvey as UpcomingSurveyComponent } from './upcoming-survey';

type UpcomingSurveyProps = {
	upcomingSurveys: UpcomingSurvey[];
	editQuestion: (topic: string, topicId: number, surveyId: number) => void;
};

type Slides = {
	ariaLabelledBy: string;
	slide: JSX.Element;
};

const UpcomingSurveys: React.FC<UpcomingSurveyProps> = ({
	editQuestion,
	upcomingSurveys,
}) => {
	const classes = useStyles();
	const surveyCards = useMemo(
		() =>
			upcomingSurveys.map(
				({ questions, scheduledDate, surveyId, surveyIsActive }) => {
					const displayDate = moment(scheduledDate).format('dddd, MMM D');

					return ifFeature(
						'encore',
						{
							ariaLabelledBy: displayDate,
							// Carousel hides overflow and causes slight overlap with this component so using 99% to fill height instead of 100%
							slide: (
								<Flex height="99%" width="min(100%, 375px)" key={displayDate}>
									<UpcomingSurveyComponent
										canEdit={!surveyIsActive}
										editQuestion={editQuestion}
										key={displayDate}
										questions={questions}
										scheduledDate={displayDate}
										surveyId={surveyId}
									/>
								</Flex>
							),
						},
						<UpcomingSurveyComponent
							canEdit={!surveyIsActive}
							editQuestion={editQuestion}
							key={displayDate}
							questions={questions}
							scheduledDate={displayDate}
							surveyId={surveyId}
						/>,
					);
				},
			),
		[upcomingSurveys, editQuestion],
	);

	const PrevButton = (props: ButtonProps) => {
		return (
			<LayoutBox position="absolute" left={0} top={0}>
				<TextButton {...props} startIcon="arrow-left-regular" type="button">
					{$.__('Previous')}
				</TextButton>
			</LayoutBox>
		);
	};

	const NextButton = (props: ButtonProps) => {
		return (
			<LayoutBox position="absolute" right={0} top={0}>
				<TextButton {...props} endIcon="arrow-right-regular" type="button">
					{$.__('More Surveys')}
				</TextButton>
			</LayoutBox>
		);
	};

	return ifFeature(
		'encore',
		<>
			<LayoutBox marginTop={3}>
				<Headline
					size="extra-small"
					icon="clipboard-list-regular"
					id="carousel-header"
				>
					{$.__('Upcoming Surveys')}
				</Headline>
			</LayoutBox>
			<LayoutBox marginBottom={3}>
				<BodyText color="neutral-strong" size="extra-small">
					{$.__(
						'Surveys are four questions, with one question from each of the following areas: happiness, personal motivators, company motivators, and relationships.',
					)}
				</BodyText>
			</LayoutBox>
			<LayoutBox width="100%" position="relative" paddingTop={5.5}>
				<FabricCarousel
					ariaLabelledBy="carousel-header"
					nextButton={<NextButton />}
					prevButton={<PrevButton />}
					slides={surveyCards as Slides[]}
					slideGap="20px"
					widthBreakpoints={{ 0: 2, 825: 3 }}
				/>
			</LayoutBox>
		</>,
		<>
			<div className={classes.sectionHeader}>
				<ClipboardPencil20x20 />
				<h5>{$.__('Upcoming Surveys')}</h5>
			</div>
			<p className={classes.upcomingSurveysDescription}>
				{$.__(
					'Surveys are four questions, with one question from each of the following areas: happiness, personal motivators, company motivators, and relationships.',
				)}
			</p>
			<Carousel>{surveyCards}</Carousel>
		</>,
	);
};

export default UpcomingSurveys;
